﻿#host .win-panel {
}


#host .win-panel-title {
}





.win-listitem {
    padding: 10px;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.win-listitem-filled {
    background-color: rgba(0, 0, 0, 0.10);
}



/*.win-label-title {
    font-size: 74px;
    font-weight: bolder;
}*/




// Hub layout corrections
.win-hub-vertical .win-hub-section {
    width: 100%;
    padding: 0;
}

.win-hub-vertical .win-hub-surface {
    width: 100%;
    padding: 0;
}

.win-hub-vertical .win-hub-section-header {
    padding-left: 10px;
}

/*h1.win-hub-section-header-content,
h2.win-hub-section-header-content,
h3.win-hub-section-header-content,
h4.win-hub-section-header-content,
h5.win-hub-section-header-content {
    .margin-clear;
    .padding-clear;
}

.margin-clear {
    margin: 0;
}
.padding-clear {
    padding: 0;
}*/



.validation-summary-errors > ul {
    list-style-type: none;
    padding: 5px 0;
}





.win-grouped-vertical > input,
.win-grouped-vertical > label {
    display: block;
}

.win-grouped-horizontal > input {
    display: inline-block;
}

.message {
    height: 100%;
    margin: 10px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1);
}

.win-dropdown {
    background-color: transparent;
}



.win-pivot.inverted button.win-pivot-header {
    color: rgba(255, 255, 255, 0.5);
}

.win-pivot.inverted button.win-pivot-header-selected.win-pivot-header {
    color: #fff;
}

html.win-hoverable .win-pivot.inverted button.win-pivot-header:hover {
    color: rgba(255, 255, 255, 0.8);
}


.win-contentdialog-dialog {
    position:relative;
}

